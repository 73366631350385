<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-form
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>
            <b-col cols="12">
              <LocaleTabs
                :errors="formErrors"
                name="name"
              >
                <template
                  v-for="(i,k,idx) in languages"
                  :slot="`lang${idx}`"
                  slot-scope="slotScope"
                >
                  <ValidationProvider
                    :key="idx"
                    v-slot="{ errors }"
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid="`name.${k}`"
                    :name="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules="required"
                  >
                    <b-form-group :label="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })">
                      <b-form-input
                        v-model="form.name[k]"
                        :placeholder="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>

            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.code')"
                vid="code"
                rules="required|min:3|max:3"
              >
                <b-form-group
                  :label="$t('labels.code')"
                >
                  <b-form-input
                    v-model="form.code"
                    :placeholder="$t('labels.code')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      form: {
        name: {
          en: null,
          ar: null,
        },
        code: null,
        country_id: this.$route.params.country_id,
      },
    }
  },
  computed: {
    isEdit() {
      return !!this.$route.params.resourceId && this.$route.meta.type === 'edit'
    },
  },
  created() {
    if (this.isEdit) {
      this.loadData()
    }
  },
  methods: {
    loadData() {
      this.axios.get(`/${this.resource}/${this.$route.params.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            name: data.name,
            code: data.code,
            country_id: data.country_id,
          }
        })
    },
  },
}
</script>
